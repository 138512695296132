<template>
   <div id="leftNavigationBar">
     <div class="menuBox" style="position: relative;">
       <el-menu  :default-active="$route.path"  :unique-opened="true" class="el-menu-vertical-demo" text-color="#fff"   :router="true">
        <el-menu-item index="/overview">
          <i class="el-icon-picture-outline-round"></i>
          <span slot="title">概览预览</span>
        </el-menu-item>
        <el-menu-item index="/Drugs">
          <i class="el-icon-phone-outline"></i>
          <span slot="title">处方管理</span>
        </el-menu-item>
        <el-menu-item index="/prescription">
          <i class="el-icon-office-building"></i>
          <span slot="title">药品管理</span>
        </el-menu-item>
        <el-menu-item index="/SelectDrugs">
          <i class="el-icon-first-aid-kit"></i>
          <span slot="title">患者选药</span>
        </el-menu-item>
        <el-menu-item index="/Patient">
          <i class="el-icon-user"></i>
          <span slot="title">档案管理</span>
        </el-menu-item>
       </el-menu>
 
       <div class="leftInfo">
        <!-- <el-button type="text" @click="newpage" style="margin-bottom: 50px;font-size: ;">药店打印此页面放置于店内</el-button> -->
        <h3 @click="newpage" style="margin-bottom: 50px;color: #E6A23C;cursor: pointer;">药店打印此页面 <br>
          
          放置于店内   <i class="el-icon-s-promotion"></i></h3>
        <!-- <h2 >{{ Pharmacy.name }}</h2> -->
        <h3>{{ Pharmacy.name }}</h3>
        <h4 style="margin-bottom: 5px;">扫码开方</h4>
        <el-image class="leftimg" :src="PharmacyUrl"  :preview-src-list="srcList"></el-image>
       </div>
       
     </div>
   </div>
</template>
<script>
import base from '../api/base'
export default {
    data(){
      return {
        userpower:[],
        PharmacyUrl:'',
        Pharmacy:{},
        srcList:[]
      }
    },
    methods:{
      newpage(){
        window.open(window.location.origin + '/#/print', '_blank');
      },
    },
    created(){
          let params = {
            search_db:'Pharmacy',
            query_type:'first',
      
        }
        let data = {
            query:{
              found_id:localStorage.getItem('userrole_id')
            }
        }
        this.$api.article.query(params,data).then(res => {
          this.Pharmacy = res.data.result
          this.PharmacyUrl = `${base.baseUrl}hospitalQR?pharmacy_id=${res.data.result.id}&token=${JSON.parse(localStorage.getItem('userinfo')).token}&userrole_id=${localStorage.getItem('userrole_id')}`
          console.log(this.PharmacyUrl)
          this.srcList.push(
            this.PharmacyUrl
          )
        })
      // this.Pharmacy = JSON.parse(localStorage.getItem('Pharmacy'))
      // this.PharmacyUrl = `${base.baseUrl}hospitalQR?pharmacy_id=${localStorage.getItem('pharmacy_id')}&token=${JSON.parse(localStorage.getItem('userinfo')).token}&userrole_id=${localStorage.getItem('userrole_id')}`
      // this.srcList.push(this.PharmacyUrl)
      // this.userpower = JSON.parse(localStorage.getItem('userpower')).get
    }

}
</script>
<style>
.leftimg{
  /* width: 200px;
  height: 200px; */
  width: 100%
}
.leftInfo{
  text-align: center;
  width: 100%;
  margin-top: 50px;
  position: relative;
  left: 15px;
  /* top: 200px; */
  bottom: -150px;
}
#leftNavigationBar{
  padding-top: 10px;
}
</style>