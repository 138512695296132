import Vue from 'vue'
import Router from 'vue-router'
import login from './login.vue'
import Drugs from './Drugs.vue'
import prescription from './prescription.vue'
import overview from './overview.vue'
import Mobile  from './Mobile .vue'
import SelectDrugs  from './SelectDrugs.vue'
import register  from './register.vue'
import guide  from './guide.vue'
import print  from './print.vue'
import Patient  from './Patient.vue'



Vue.use(Router)

export default new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/", // 登陆
            name: 'login',
            component: login
        },
        {
            path: "/overview", // 概览
            name: 'overview',
            component: overview
        },
        {
            path: "/Drugs", // 商品
            name: 'Drugs',
            component: Drugs
        },
        {
            path: "/prescription", // 处方管理
            name: 'prescription',
            component: prescription
        },
        {
            path: "/Mobile", // 移动端 二维码页面
            name: 'Mobile',
            component: Mobile
        },
        {
            path: "/SelectDrugs", // 帮助患者选药
            name: 'SelectDrugs',
            component: SelectDrugs
        },
        {
            path: "/register", // 注册
            name: 'register',
            component: register
        },
        {
            path: "/guide", // 引导
            name: 'guide',
            component: guide
        },
        {
            path: "/print", // 引导
            name: 'print',
            component: print
        },
        {
            path: "/Patient", // 就诊人
            name: 'Patient',
            component: Patient
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})