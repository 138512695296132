import { render, staticRenderFns } from "./Drugs.vue?vue&type=template&id=116e6cf1"
import script from "./Drugs.vue?vue&type=script&lang=js"
export * from "./Drugs.vue?vue&type=script&lang=js"
import style0 from "./Drugs.vue?vue&type=style&index=0&id=116e6cf1&prod&scopde=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports