<template>
  <div class="header">
      <div class="header_icon">
        <img  src="../assets/image/321.png" alt="" style="align-self: center;">
        <h2 style="color:#409EFF">尙云方统筹问诊平台-药店端</h2>
      </div>
      <div style="display: flex; align-items: center;">
        <el-button icon="el-icon-notebook-2" @click="guide" class="headerbtn" style="margin-right: 30px" type="text">产品介绍</el-button>
        <div class="headercodeimg">
          <el-image 
          :src="PharmacyUrl" 
          :preview-src-list="srcList">
        </el-image>
        </div>
        <el-divider direction="vertical"></el-divider><img @click="handleCommand({command:'edit'})" class="edit" src="../assets/image/edit.png" alt="" > <el-divider direction="vertical"></el-divider>

        <el-dropdown trigger="click"  @command="handleCommand" style="margin-right: 30px">
          <div style="display:flex;align-items: center;">
              <img src="../assets/feiImg/16.png" alt="">
             
              &nbsp;&nbsp;&nbsp;
              <div>{{ loginname }}</div>
          </div>
          <el-dropdown-menu  slot="dropdown">
            <el-dropdown-item  icon="el-icon-s-tools" :command="composeValue('edit')">编辑信息</el-dropdown-item>
            <el-dropdown-item icon="el-icon-key" :command="composeValue('password')" divided>修改密码</el-dropdown-item>
            <el-dropdown-item icon="el-icon-switch-button" :command="composeValue('LogOut')" divided>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        

        <img @click="logout()" src="../assets/feiImg/15.png" alt="" style="cursor:pointer;">
      </div>
      <el-dialog
      title="产品介绍"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose">
      <div style="position: absolute; top: 0;left: 100px;top: 20px;"><el-button type="text" @click="newpage">新页面打开</el-button></div>
      <el-row :gutter="20">
        <el-col :span="20">
          <h2>视频约15分钟，视频看懂后，玩转问诊统筹</h2>
          <video style="width: 100%; height: 100%;"  ref="myPlayer" controls></video>
        </el-col>
        <el-col :span="4" >
          <el-row >
            <el-col :span="24">
              <h2>手机查看视频</h2>
              <el-image 
                style="width: 100%"
                :src="guideQRcode" 
                >
              </el-image>
            </el-col>
          </el-row>
          <el-row  style="position: absolute; bottom: 0;">
            <el-col :span="24" >
              <h2>产品 Q&A 文档</h2>
              <el-image 
                style="width: 100%"
                :src="documentQRcode" 
                >
              </el-image>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      </el-dialog>
      <el-dialog
      :visible.sync="drawer"
      width="50%"
      height="70%"
      :before-close="handleClose">

      <div id="Interrogation" class="content time_slot SelectDrugs">
        <div class="print">
          <h1 class="margin-top title">推荐使用尚云方进行医保统筹</h1>
          <el-row :gutter="20" class="margin-top">
            <!-- <el-col :span="12" class="text-align">
                <h1>1：公众号问诊</h1>
                <img class="img" :src="officialAccount" alt="">
            </el-col> -->
            <el-col :span="12" class="text-align">
                <h1>扫码问诊</h1>
                <img class="img" :src="PharmacyUrl" alt="">
            </el-col>
            <el-col :span="12" class="text-align">
                <h1>扫码建档</h1>
                <img class="img" :src="`${PharmacyUrl}&action=achive`" alt="">
            </el-col>
          </el-row>
          <h1 class="header-buttomtitle">请截图此页面打印后放置于店内</h1>
        </div>
      </div>

      
      <!-- <div style="position: absolute; top: 0;left: 100px;top: 20px;"><el-button type="text" @click="newpage">新页面打开</el-button></div>
      <el-row :gutter="20">
        <el-col :span="20">
          <h2>视频约15分钟，视频看懂后，玩转问诊统筹</h2>
          <video style="width: 100%; height: 100%;"  ref="myPlayer" controls></video>
        </el-col>
        <el-col :span="4" >
          <el-row >
            <el-col :span="24">
              <h2>手机查看视频</h2>
              <el-image 
                style="width: 100%"
                :src="guideQRcode" 
                >
              </el-image>
            </el-col>
          </el-row>
          <el-row  style="position: absolute; bottom: 0;">
            <el-col :span="24" >
              <h2>产品 Q&A 文档</h2>
              <el-image 
                style="width: 100%"
                :src="documentQRcode" 
                >
              </el-image>
            </el-col>
          </el-row>
        </el-col>
      </el-row> -->
      </el-dialog>


      <el-dialog
      :visible.sync="pharmacydrawer"
      width="50%"
      height="70%"
      title="请输入您药店在医保正确的机构名称和编码，否则不可申请统筹问诊"
      :before-close="pharmacydrawerhandleClose">
      <el-form ref="form" >
          <el-form-item label="机构名称">
              <el-input v-model="pharmacy_form.name" placeholder="山西某某药业连锁有限公司大同某某店"></el-input>
            </el-form-item>
            <el-form-item label="机构编码（贯标码）">
              <el-input v-model="pharmacy_form.yb_code" placeholder="P14021400888"></el-input>
            </el-form-item>
          <el-form-item>
            <el-button @click="pharmacydrawerhandleClose">取消</el-button>
            <el-button type="primary" @click="pharmacy_put">确认</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
  </div>
</template>
<script>
import base from '../module/api/base'
import Hls from 'hls.js';
import mixin from '@/utils/mixin'

export default {
  mixins: [mixin],
  data() {
    return {
      loginname:'',
      state:0,
      PharmacyUrl:'',
      dialogVisible:false,
      drawer:false,
      guideQRcode:require("../assets/image/guideQRcode.png"),
      documentQRcode:require("../assets/image/documentQRcode.png"),
      RefundQRcode:require("../assets/image/RefundQRcode.png"),
      empowerCode:require("../assets/image/empowerCode.png"),
      officialAccount:require("../assets/image/officialAccount.jpg"),
      pharmacydrawer:false,
      PharmacyUrl:'',
      pharmacy_form:{
        name:'',
        yb_code:'',
      },
      srcList:[
        
      ]
    };
  },
  mounted() {
    console.log('P14021400888'.length)
    let params = {
        search_db:'Pharmacy',
        query_type:'first',
  
    }
    let data = {
        query:{
          found_id:localStorage.getItem('userrole_id')
        }
    }
    this.$api.article.query(params,data).then(res => {
      this.loginname = res.data.result.name
      localStorage.setItem("pharmacy_id",res.data.result.id);
      localStorage.setItem("Pharmacy",JSON.stringify(res.data.result));
      this.PharmacyUrl = `${base.baseUrl}hospitalQR?pharmacy_id=${res.data.result.id}&token=${JSON.parse(localStorage.getItem('userinfo')).token}&userrole_id=${localStorage.getItem('userrole_id')}`
      this.srcList.push(
        this.PharmacyUrl
      )
      if(res.data.result.yb_code.length !==12){
        this.pharmacydrawer = true
      }
    })
    if(!sessionStorage.getItem('guide')){
      this.drawer = true
      sessionStorage.setItem('guide', 'true')
    }
  },
  methods:{
    pharmacy_put(){
      if(this.pharmacy_form.name ==''){
        this.$message.error('请输入药店名称');
        return
      }
      if(this.pharmacy_form.yb_code.length !==12){
        this.$message.error('请输入正确的机构编码（贯标码）');
        return
      }
      let data = {
        name:this.pharmacy_form.name,
        yb_code:this.pharmacy_form.yb_code,
      }
      this.putstate('Pharmacy','id',localStorage.getItem("pharmacy_id",),data , false)
      this.pharmacydrawer = false
    },
    newpage(){
      console.log(window.location)
      window.open(window.location.origin + '/#/guide', '_blank');
    },
    guide(){
      this.dialogVisible = true
      let that = this
        setTimeout(function () {
          console.log(that.loadList)
          that.loadList()
        }, 1000)
      sessionStorage.setItem('guide', 'true')
    },
    changeuser(){},
    logout(){
        this.$confirm('您将要退出账号, 是否继续?', '退出', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.$router.push({path:`/${localStorage.getItem('login_role') ? `?role=${localStorage.getItem('login_role')}` : ''}`});
            
            localStorage.clear();
            this.$notify({
                type: 'success',
                message: '您已退出登录！'
            });
        }).catch(() => {
        });
    },
    handleCommand(command){
      console.log(command)
      if(command.command == 'LogOut'){
        this.logout();
      }else if(command.command == 'edit'){
        // this.$router.push({path:'/UserInfo'});
        this.pharmacydrawer = true
      }else if(command.command == 'password'){
        this.$router.push({path:'/Password'});
      }
    },
    composeValue(command){
      return {
          command:command
      }
    },
    handleClose(key){
      this.dialogVisible = false
        this.drawer = false
    },
    pharmacydrawerhandleClose(){
      this.pharmacydrawer = false
    },
    loadList(){
        const video = this.$refs.myPlayer;
        console.log(video)
        const hls = new Hls();
        hls.loadSource(`https://cloud.net-sun.com/manual/video/shangyunfangv3.m3u8`);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
        });
        if (this.$refs.myPlayer.canPlayType('application/vnd.apple.mpegurl')) {
        // 如果浏览器原生支持播放M3U8文件
        this.$refs.myPlayer.src = `https://cloud.net-sun.com/manual/video/shangyunfangv3.m3u8`
        }

        sessionStorage.setItem('guide', 'true')
    },
  }
};
</script>
<style lang="less" scoped>
.edit{
  width: 30px;
}
.header-buttomtitle{
    font-size: 30px;
    color: #E6A23C;
    text-align: center;
}
.headerbtn{
  color: red;
  font-family: 800;
  font-size: 25px;
}
.header{
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  margin: 0 auto;
  //height: 119px;
  //text-align: right;
  background: #fff !important;
  box-shadow: 0 12px 40px 0 rgba(148,186,215,0.2);
  flex-shrink: 0;
  padding: 0 0.15rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}
.header_icon{
  display: flex;
  align-items: center;
}
.header_icon img{
  width: 0.8rem;
}
.headercodeimg{
  width: 50px;
  height: 50px;
}
</style>